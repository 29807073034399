
import { defineComponent } from '@vue/runtime-core';
import { doc, getDoc } from 'firebase/firestore';
import { collectionRef } from '../helpers';

type comment = { text: string, timestamp: number, uid?: string };

export default defineComponent({
    async beforeMount(){
        const data = await getDoc(doc(collectionRef('messages'), 'comments'));
        const comments = data.data() as { [key: string]: comment[] };
        this.comments = Object.entries(comments).map(([uid, commentList]) => {
            commentList.forEach(comment => comment.uid = uid);
            return commentList;
        }).flat();
    },
    data: () => ({
        comments: [] as comment[],
    })
});
