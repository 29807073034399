<template>
    <section>
        <div class='parent'>
            <div v-for='{ text, uid }, index in comments' class='commentRow' :key='index'>
                <div className='userId'>{{ uid || 'ללא מזהה' }}</div>
                <div className='commentText'>{{ text }}</div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { doc, getDoc } from 'firebase/firestore';
import { collectionRef } from '../helpers';

type comment = { text: string, timestamp: number, uid?: string };

export default defineComponent({
    async beforeMount(){
        const data = await getDoc(doc(collectionRef('messages'), 'comments'));
        const comments = data.data() as { [key: string]: comment[] };
        this.comments = Object.entries(comments).map(([uid, commentList]) => {
            commentList.forEach(comment => comment.uid = uid);
            return commentList;
        }).flat();
    },
    data: () => ({
        comments: [] as comment[],
    })
});
</script>

<style lang="scss" scoped>
.commentRow {
    display: flex;
    padding: 0.3rem 1rem;

    & .userId {
        background-color: $swatchA;
        padding: 0.5rem 1rem;
        border: $borderLight;
    }

    & .commentText {
        padding: 0.5rem;
    }
}
</style>